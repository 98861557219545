import * as React from 'react'

import { useQuery } from '@apollo/client'
import {
  AccountBalance,
  CreditCard,
  Description,
  History as HistoryIcon,
  RequestQuote,
  ShowChart,
  Upload,
} from '@mui/icons-material'
import { Link, Redirect, Router, useLocation } from '@reach/router'

import {
  DrawerDivider,
  DrawerList,
  DrawerListItem,
  Loading,
  UserNotificationsIcon,
} from 'shared/components'
import { USER_NOTIFICATIONS_QUERY } from 'shared/queries'

import BankAccounts from '../components/views/bank_accounts'
import Documents from '../components/views/documents'
import History from '../components/views/history'
import NotFound from '../components/views/not_found'
import Notifications from '../components/views/notifications'
import Portfolio from '../components/views/portfolio'
import Purchase from '../components/views/purchase'
import Quote from '../components/views/quote'
import Security from '../components/views/security'
import Signature from '../components/views/signature'
import Support from '../components/views/support'
import Withdraw from '../components/views/withdraw'
import Layout from '../layouts/app'

import type {
  UserNotificationsData,
  UserNotificationsVars,
} from 'shared/queries'

const App = () => {
  const pathname = useLocation().pathname.replace(/\/$/, '')
  const basepath = '/app'

  const { loading, data } =
    useQuery<UserNotificationsData, UserNotificationsVars>(
      USER_NOTIFICATIONS_QUERY, {
        notifyOnNetworkStatusChange: true,
      })

  const isCurrentPath = (href: string): boolean => pathname.startsWith(basepath + '/' + href)

  const listItem = (text: string, href: string, icon: React.ReactNode, color?: string) => (
    <DrawerListItem
      text={text}
      href={href}
      icon={icon}
      color={color}
      selected={isCurrentPath(href)}
      component={Link}
    />
  )

  const drawerContent = (
    <React.Fragment>
      <DrawerList>
        {listItem('Portafolio', 'portfolio', <AccountBalance />)}
        {listItem('Cotizar', 'quote', <RequestQuote />)}
        {listItem('Comprar', 'purchase', <CreditCard />)}
      </DrawerList>
      <DrawerDivider />
      <DrawerList>
        {listItem('Historial', 'history', <HistoryIcon />)}
        {listItem('Retirar', 'withdraw', <Upload />)}
      </DrawerList>
      <DrawerDivider />
      <DrawerList>
        {listItem('Documentos', 'documents', <ShowChart />)}
        {listItem('Notificaciones', 'notifications', (
          <UserNotificationsIcon
            loading={loading}
            userNotifications={data?.userNotifications}
          />
        ))}
      </DrawerList>
      <DrawerDivider />
      <DrawerList>
        {listItem('Soporte', 'support', <Description />)}
      </DrawerList>
    </React.Fragment>
  )

  return loading ? (
    <Loading />
  ) : (
    <Router basepath={basepath}>
      <Layout
        path='/'
        drawerContent={drawerContent}
      >
        <Redirect
          noThrow
          from='/'
          to='portfolio'
        />
        <Portfolio path='portfolio' />
        <Purchase path='purchase' />
        <Documents path='documents' />
        <Notifications path='notifications' />
        <History path='history' />
        <BankAccounts path='bank' />
        <Signature path='signature' />
        <Quote path='quote' />
        <Support path='support' />
        <Security path='security' />
        <Withdraw path='withdraw' />
        <NotFound default />
      </Layout>
    </Router>
  )
}

export default App
