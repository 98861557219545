/* eslint-disable max-len */
import * as React from 'react'

type NavLogoProps = {
  href?: string
  height?: string | number
}

export const NavLogo = ({ href, height }: NavLogoProps) => (
  <a
    href={href || '/'}
    style={{ display: 'flex' }}
  >
    <svg
      xmlns='http://www.w3.org/2000/svg'
      viewBox='0 0 612.83 101.56'
      height={height}
    >
      <rect
        fill='#d1e4f2'
        width='101.56'
        height='101.56'
        rx='2.58'
        ry='2.58'
      />
      <rect
        fill='#156acb'
        x='22.86'
        y='22.92'
        width='55.59'
        height='55.59'
        rx='2.58'
        ry='2.58'
      />
      <g fill='#156acb'>
        <path d='M132.23,51.07c0-11.9,0-23.8-.02-35.71,0-.82.16-1.09,1.05-1.08,10.43.03,20.86-.05,31.28.05,6.69.07,13.21,1.23,19.31,4.14,7.72,3.68,12.94,9.62,15.54,17.72,3.09,9.62,3.14,19.36.25,29.01-2.98,9.96-9.69,16.52-19.46,19.97-5.62,1.98-11.45,2.66-17.39,2.66-9.9-.01-19.8-.01-29.7.02-.81,0-.88-.31-.88-.97.02-11.93.01-23.87.01-35.8ZM148.02,51.01c0,7.06,0,14.13-.02,21.19,0,.71.15.95.91.94,4.56-.04,9.12,0,13.68-.02,4.17-.02,8.3-.29,12.28-1.7,4.51-1.6,7.65-4.58,9.32-9.08.86-2.31,1.32-4.7,1.5-7.15.36-4.85.29-9.67-1.19-14.35-1.43-4.56-4.25-7.91-8.76-9.74-3.74-1.52-7.66-2.01-11.65-2.06-5.06-.06-10.11,0-15.17-.04-.73,0-.92.19-.92.92.03,7.03.02,14.07.02,21.1Z' />
        <path d='M492.22,44.25c1.71-.87,3.26-2.01,5.01-2.81,5.03-2.3,10.32-3.05,15.8-2.69,5.14.34,10.04,1.55,14.51,4.22,5.62,3.37,9.03,8.28,10.3,14.67.97,4.85.64,9.62-1.19,14.24-1.81,4.59-4.73,8.34-8.73,11.22-6.4,4.61-13.63,6.14-21.4,5.67-4.37-.26-8.53-1.31-12.4-3.31-6.48-3.35-10.83-8.64-13.64-15.3-2.01-4.77-2.95-9.77-3.31-14.9-.47-6.76.04-13.45,2.06-19.95,2.51-8.12,7.04-14.72,14.74-18.81,4.57-2.43,9.5-3.37,14.64-3.28,4.03.07,7.96.67,11.74,2.15,6.88,2.68,11.65,7.49,14.51,14.25.87,2.06,1.56,4.17,2.01,6.37.1.5.02.66-.51.66-5.09-.01-10.18-.01-15.27,0-.43,0-.65-.12-.8-.56-1.86-5.22-5.68-7.75-11.11-8.08-3.64-.22-7.13.21-10.25,2.26-3.14,2.06-4.75,5.16-5.75,8.62-.51,1.74-1,3.51-.92,5.36-.04.02-.07.03-.11.05.02,0,.04.01.05,0,.02-.02.03-.05.04-.07ZM508.27,73.3c3.71,0,6.37-.68,8.64-2.14,6.08-3.91,6.27-12.36.17-16.1-5.19-3.18-10.79-3.24-16.14-.39-7.17,3.82-6.5,12.82-.79,16.45,2.6,1.65,5.49,2.21,8.12,2.19Z' />
        <path d='M612.83,51.42c-.07,5.51-.77,11.21-2.88,16.66-4.27,11.04-12.17,17.94-23.84,20.17-7.77,1.49-15.41.74-22.59-2.84-7.32-3.64-12.15-9.55-15.08-17.09-1.99-5.12-2.81-10.44-2.94-15.9-.14-5.9.5-11.7,2.45-17.29,3.29-9.45,9.4-16.29,18.91-19.81,4.62-1.71,9.44-2.28,14.36-2.02,4.77.25,9.34,1.28,13.61,3.43,7.89,3.97,12.82,10.44,15.61,18.68,1.73,5.1,2.34,10.37,2.39,16.01ZM597.06,50.9c-.08-3.15-.33-6.57-1.28-9.91-1.64-5.76-5.05-9.8-11.03-11.32-3.47-.88-6.98-.89-10.51-.19-5.07,1.01-8.63,3.86-10.73,8.55-1.27,2.83-1.84,5.84-2.07,8.91-.42,5.51-.26,10.98,1.75,16.21,1.72,4.47,4.79,7.6,9.45,9.04,3.17.98,6.4,1.09,9.66.72,6.32-.7,10.69-3.97,12.93-9.95,1.42-3.8,1.73-7.78,1.82-12.08Z' />
        <path d='M346.59,36.7c0-2.1.03-3.95-.01-5.8-.01-.65.17-.83.83-.83,4.28.03,8.57.04,12.85,0,.84,0,1.07.19,1.07,1.06-.03,17.52-.02,35.03-.02,52.55,0,1.15-.02,2.29,0,3.44.01.48-.07.72-.64.72-4.47-.02-8.94-.02-13.41,0-.54,0-.69-.17-.68-.69.03-1.92.01-3.84.01-5.81-.12.03-.23.03-.26.08-2.96,4.06-7.14,6.08-11.94,6.86-6.98,1.13-13.94.94-20.7-1.28-8.23-2.7-13.5-8.34-15.72-16.7-1.86-7.01-1.94-14.09-.4-21.17.9-4.13,2.54-7.94,5.33-11.21,2.93-3.43,6.63-5.72,10.87-7.04,7.28-2.26,14.71-2.45,22.16-.92,3.79.78,7.5,2.46,10.67,6.75ZM328.99,74.72c2.29-.08,4.77-.26,7.16-.98,4.79-1.45,8.22-4.35,9.67-9.28.82-2.81.96-5.67.53-8.55-.73-4.9-3.1-8.7-7.71-10.74-6.23-2.76-12.61-2.72-18.91-.29-3.92,1.51-6.51,4.46-7.71,8.51-.96,3.24-1,6.54-.31,9.83.85,4.08,3.08,7.15,6.76,9.19,3.23,1.78,6.74,2.21,10.52,2.33Z' />
        <path d='M462.61,51.07c2.46,1.69,4.45,3.79,5.97,6.33,2.41,4.04,3.09,8.39,2.34,12.98-1.35,8.25-6.43,13.38-13.96,16.33-5.46,2.14-11.13,2.55-16.93,1.89-3.83-.44-7.49-1.39-10.9-3.22-5.2-2.79-8.77-6.99-10.89-12.47-1.18-3.06-1.8-6.24-2.18-9.48-.07-.58.04-.81.7-.81,4.91.03,9.81.03,14.72,0,.61,0,.76.22.84.78.27,2.04.75,4.01,1.71,5.85,1.72,3.3,4.45,5.16,8.09,5.72,3.16.49,6.25.3,9.16-1.2,4.63-2.4,6.59-7.94,4.27-12.24-1.39-2.57-3.79-3.76-6.48-4.42-2.92-.72-5.92-.57-8.89-.63q-1.62-.03-1.62-1.62c0-2.7.03-5.4-.02-8.09-.01-.81.16-1.09,1.04-1.11,2.51-.06,5.03.04,7.53-.3,2.63-.35,5.14-1.07,7.08-2.98,3.25-3.2,3.02-8.98-.33-12.14-3.18-2.99-6.98-3.67-11.14-3.18-4.07.48-7.08,2.47-8.88,6.19-.83,1.72-1.28,3.56-1.51,5.44-.08.68-.3.85-.96.84-4.84-.03-9.69-.02-14.53,0-.55,0-.86-.04-.78-.74.55-4.91,1.69-9.64,4.34-13.89,3.02-4.86,7.34-8.14,12.74-9.89,7.05-2.29,14.23-2.41,21.36-.42,5.29,1.48,9.91,4.15,13.12,8.77,3.15,4.54,4.2,9.62,3.31,15.04-.85,5.17-3.58,9.23-7.81,12.3-.15.11-.29.22-.48.37Z' />
        <path d='M240.58,29.01c5.02.05,10.51.72,15.71,2.94,6.03,2.57,10.6,6.69,13.31,12.73,2.78,6.2,3.29,12.71,2.53,19.38-.47,4.11-1.65,7.99-3.75,11.58-2.9,4.95-7.11,8.37-12.42,10.46-6.88,2.71-14.03,3.2-21.32,2.5-4.75-.45-9.26-1.65-13.47-3.93-4.69-2.53-8.16-6.21-10.47-10.98-2.5-5.18-3.18-10.69-2.99-16.37.12-3.65.68-7.21,1.92-10.65,2.16-5.99,6.03-10.53,11.67-13.55,5.85-3.14,12.18-4.04,19.28-4.1ZM240.05,74.73c2.37-.08,4.85-.26,7.24-.98,4.88-1.47,8.28-4.48,9.72-9.47.79-2.75.9-5.56.47-8.37-.82-5.4-3.65-9.3-8.83-11.23-5.32-1.99-10.75-2.02-16.17-.41-4.68,1.39-7.93,4.37-9.34,9.14-.9,3.05-1.05,6.18-.44,9.29.99,5.09,3.85,8.68,8.75,10.6,2.74,1.07,5.59,1.33,8.59,1.45Z' />
        <path d='M367.27,58.87c0-9.21,0-18.42,0-27.62q0-1.27,1.3-1.28c4.13,0,8.26.02,12.39-.02.82,0,1.06.25,1.05,1.06-.05,2.57-.02,5.15,0,7.72,0,.21-.17.55.19.62.23.05.35-.21.47-.39,3.25-4.77,7.94-7.23,13.47-8.3,4.42-.86,8.89-.68,13.36-.7.58,0,.74.27.73.81-.01,3.72-.02,7.44,0,11.16,0,.73-.39.79-.95.79-2.36,0-4.72-.05-7.08.04-5.87.23-11.15,2.05-15.38,6.26-2.94,2.92-4.73,6.49-4.78,10.67-.1,9.02-.05,18.04-.03,27.07,0,.81-.18,1.04-1.02,1.04-4.13-.05-8.26-.02-12.39-.02q-1.33,0-1.33-1.38c0-9.18,0-18.35,0-27.53Z' />
        <path d='M277.29,51.05c0-11.87.01-23.75-.03-35.62,0-.98.28-1.15,1.18-1.14,4.25.05,8.51.04,12.76,0,.66,0,.81.19.81.83-.02,23.97-.02,47.93,0,71.9,0,.67-.2.82-.84.81-4.32-.03-8.63-.03-12.95,0-.78,0-.97-.21-.97-.98.03-11.94.02-23.87.02-35.81Z' />
        <path d='M229.32,25.84c1.83-4.24,3.6-8.33,5.36-12.41.67-1.56,1.35-3.12,2.02-4.68.15-.34.26-.66.76-.65,4.31.02,8.62.01,12.91.01.15.39-.13.57-.28.78-3.95,5.47-7.91,10.94-11.86,16.42-.22.31-.42.56-.87.55-2.63-.02-5.27-.01-8.04-.01Z' />
        <path d='M492.22,44.25s-.03.05-.04.07c0,0-.03,0-.05,0,.04-.02.07-.03.11-.05,0,0-.01-.02-.01-.02Z' />
      </g>
    </svg>
  </a>
)

export default NavLogo
