import * as React from 'react'

import { RequestQuote } from '@mui/icons-material'
import {
  Button,
  FormControl,
  Grid,
  InputAdornment,
  List,
  ListItem,
  ListItemText,
  Stack,
  Typography,
} from '@mui/material'

import { AppContainer, Currency, SeoHeaders } from 'shared/components'
import { BaseCurrencyField } from 'shared/forms'

import { BankTransferDialog } from '../bank_transfer_dialog'

import type { RouteComponentProps } from '@reach/router'

const CalcNextDepositAmount = () => {
  const [depositAmount, setDepositAmount] = React.useState<number | undefined>(0)

  return (
    <React.Fragment>
      <AppContainer
        sm={12}
        md={6}
        sx={{
          p: 3,
          height: '100%',
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
          flexDirection: 'column',
          gap: 3,
        }}
      >
        <Typography
          variant='h6'
          textAlign='center'
        >
          Simular compra
        </Typography>
        <FormControl fullWidth>
          <BaseCurrencyField
            label='Monto a depositar'
            InputProps={{
              startAdornment: (
                <InputAdornment position='start'>
                  <small>CLP</small>&nbsp;$
                </InputAdornment>
              ),
            }}
            onChange={setDepositAmount}
            value={depositAmount}
            digits={0}
            positive
          />
        </FormControl>
        <BankTransferDialog />
      </AppContainer>
      <AppContainer
        sm={12}
        md={6}
        sx={{ p: 3, height: '100%' }}
      >
        <Stack
          height='100%'
          justifyContent='center'
        >
          <List>
            <ListItem
              disableGutters
              divider
            >
              <ListItemText primary='Total a recibir' />
              <ListItemText
                sx={{ textAlign: 'right', textOverflow: 'ellipsis' }}
                primary={(
                  <Currency
                    currency='USD'
                    digits={2}
                    value={depositAmount ? depositAmount / 920 : 0}
                  />
                )}
              />
            </ListItem>
            <ListItem disableGutters>
              <ListItemText primary='Precio' />
              <ListItemText
                sx={{ textAlign: 'right', textOverflow: 'ellipsis' }}
                primary={(
                  <Currency
                    currency='CLP/USD'
                    digits={2}
                    value={920}
                  />
                )}
              />
            </ListItem>
          </List>
        </Stack>
      </AppContainer>
    </React.Fragment>
  )
}

const phoneNumber = '56971622179'
const message = '[Dólar360] Hola Equipo comercial! Quiero realizar una compra, me gustaría cotizar '

// eslint-disable-next-line @typescript-eslint/no-unused-vars
const Quote = (props: RouteComponentProps) => (
  <React.Fragment>
    <SeoHeaders title='Cotizar' />
    <Grid
      container
      maxWidth='md'
      mx='auto'
      spacing={3}
    >
      <CalcNextDepositAmount />
      <AppContainer sx={{ px: 3, py: 5 }}>
        <Stack
          spacing={3}
          alignItems='center'
        >
          <Typography
            variant='h4'
            textAlign='center'
          >
            Cotiza el mejor precio en línea
          </Typography>
          <Typography
            variant='subtitle1'
            textAlign='center'
          >
            Ponte en contacto con tu ejecutivo de tasas preferenciales
            y cierra el mejor precio.
          </Typography>
          <Button
            target='_blank'
            href={`https://wa.me/${phoneNumber}?text=${encodeURIComponent(message)}`}
            rel='noopener noreferrer'
            size='large'
            variant='contained'
            startIcon={<RequestQuote />}
          >
            Cotizar
          </Button>
        </Stack>
      </AppContainer>
    </Grid>
  </React.Fragment>
)
export default Quote
