import * as React from 'react'

import { useQuery } from '@apollo/client'
import { Grid } from '@mui/material'

import {
  AppContainer,
  ButtonsContainer,
  GraphUnitButtons,
  MainBalance,
  RangeButtons,
  SeoHeaders,
  TickerPricesGraph,
  VariationBalance,
} from 'shared/components'
import { TICKER_PRICES_QUERY } from 'shared/queries'
import { getTimeframeData, hourlyTimestamp } from 'shared/services'

import type { RouteComponentProps } from '@reach/router'
import type { AllTickerPricesVars, TickerPricesData, TickerPricesVars } from 'shared/queries'

type Range = AllTickerPricesVars['range']

const graphHeight = 280

// eslint-disable-next-line @typescript-eslint/no-unused-vars
const Portfolio = (props: RouteComponentProps) => {
  const [range, setRange] = React.useState<Range>('24h')
  const [graphUnit, setGraphUnit] = React.useState<string>('USD')

  const { loading, data } =
    useQuery<TickerPricesData, TickerPricesVars>(TICKER_PRICES_QUERY, {
      variables: {
        symbol: 'USDT',
        range: range,
        endTimestamp: hourlyTimestamp(),
      },
    })

  const timeframeData = getTimeframeData(data?.tickerPrices || [], 'USDT', 'USDT')

  return (
    <React.Fragment>
      <SeoHeaders title='Portafolio' />
      <Grid
        container
        spacing={3}
      >
        <MainBalance
          loading={loading}
          currency={graphUnit}
          assetAmount={0}
          assetUsdPrice={0}
        />
        <VariationBalance
          loading={loading}
          currency={graphUnit}
          range={range}
          variation={0}
        />
        <ButtonsContainer>
          <GraphUnitButtons
            disabled={loading}
            keys={['USD', 'CLP']}
            graphUnit={graphUnit}
            setGraphUnit={setGraphUnit}
            containerProps={{ justifyContent: 'flex-end' }}
          />
          <RangeButtons
            disabled={loading}
            keys={['24h', '7d']}
            range={range}
            setRange={setRange}
            containerProps={{ justifyContent: 'flex-end' }}
          />
        </ButtonsContainer>
        <AppContainer sx={{ height: graphHeight, py: 1 }}>
          <TickerPricesGraph
            currency='USD'
            isStableCoin={true}
            data={timeframeData.prices}
            ath={timeframeData.ath}
            atl={timeframeData.atl}
          />
        </AppContainer>
      </Grid>
    </React.Fragment>
  )
}

export default Portfolio
