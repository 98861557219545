import * as React from 'react'

import { useQuery } from '@apollo/client'
import {
  Button,
  Divider,
  InputAdornment,
  Paper,
  Stack,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Typography,
  darken,
  lighten,
  useMediaQuery,
} from '@mui/material'
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns'
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider'
import esLocale from 'date-fns/locale/es'
import { Form, Formik } from 'formik'
import * as Yup from 'yup'

import {
  ButtonContainer,
  ButtonsContainer,
  Currency,
  CurrencyCell,
  ErrorDisplay,
  Loading,
  Table,
} from 'shared/components'
import { CurrencyField } from 'shared/forms'
import { BULK_PURCHASE_QUOTE_QUERY } from 'shared/queries'
import { numberFormatter } from 'shared/services'

import type { Theme } from '@mui/material'
import type { FormikProps } from 'formik'
import type {
  BulkPurchaseConfig,
  BulkPurchaseQuoteData,
  BulkPurchaseQuoteVars,
} from 'shared/queries'

const ConfigDisclaimer = ({ config }: { config: BulkPurchaseConfig }) => (
  <Paper
    variant='outlined'
    sx={{ overflow: 'hidden' }}
  >
    <Table>
      <TableHead>
        <TableRow>
          <TableCell colSpan={2}>Límites de compra instantánea</TableCell>
        </TableRow>
      </TableHead>
      <TableBody>
        <TableRow>
          <TableCell>Monto mínimo</TableCell>
          <CurrencyCell
            currency='CLP'
            digits={0}
            value={config.minAmount}
          />
        </TableRow>
        <TableRow>
          <TableCell>Monto máximo</TableCell>
          <CurrencyCell
            currency='CLP'
            digits={0}
            value={config.maxAmount}
          />
        </TableRow>
        <TableRow>
          <TableCell>Máximo diario</TableCell>
          <CurrencyCell
            currency='CLP'
            digits={0}
            value={config.dailyMaxAmount}
          />
        </TableRow>
        <TableRow>
          <TableCell>Límite diario disponible</TableCell>
          <CurrencyCell
            currency='CLP'
            digits={0}
            value={config.dailyRemainingAmount}
          />
        </TableRow>
      </TableBody>
    </Table>
  </Paper>
)

const formatCurrency = (amount: number) => numberFormatter(0).format(amount)

type LargeAmountDisplayProps = {
  currency: string
  value: number
  text: string
}

const LargeAmountDisplay = ({
  currency,
  value,
  text,
}: LargeAmountDisplayProps) => (
  <Stack width='50%'>
    <Typography
      variant='h4'
      component='p'
      fontWeight={600}
      lineHeight='1.2'
      textAlign='center'
    >
      <Currency
        currency={currency}
        digits={2}
        value={value}
      />
    </Typography>
    <Typography
      variant='h6'
      component='p'
      textAlign='center'
    >
      {text}
    </Typography>
  </Stack>
)

type FormValues = {
  inAmount: number
}

const initialValues: FormValues = ({
  inAmount: 0,
})

const validationSchema = (config: BulkPurchaseConfig): Yup.SchemaOf<FormValues> => {
  const maxAmount = Math.min(config.maxAmount, config.dailyRemainingAmount)

  return (
    Yup.object().shape({
      inAmount: Yup.number()
        .typeError('Debes ingresar un número')
        .required('Este campo es obligatorio')
        .positive('Debes ingresar un monto mayor a cero')
        .integer('Debes introducir un monto sin decimales')
        .min(config.minAmount,
          `Debes ingresar un monto mayor a ${formatCurrency(config.minAmount)}`)
        .max(maxAmount, `Debes ingresar un monto inferior a ${formatCurrency(maxAmount)}`),
    })
  )
}

type InnerFormProps = FormikProps<FormValues> & {
  config: BulkPurchaseConfig
  quotePrice: number
}

const InnerForm = ({
  isSubmitting,
  status,
  submitForm,
  values,
  config,
  quotePrice,
}: InnerFormProps) => {
  const isOnLargeScreen = useMediaQuery((theme: Theme) => theme.breakpoints.up('sm'))

  return (
    <Form>
      <Stack spacing={3}>
        <Typography
          variant='h6'
          textAlign='center'
        >
          Crear orden de compra
        </Typography>
        <ConfigDisclaimer config={config} />
        <CurrencyField
          name='inAmount'
          label='Monto a comprar'
          InputProps={{
            startAdornment: (
              <InputAdornment position='start'>
                <small>CLP</small>&nbsp;$
              </InputAdornment>
            ),
          }}
          digits={0}
          positive
        />
        <Paper
          variant='outlined'
          sx={(theme) => ({
            p: 2,
            gap: 1,
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            justifyContent: 'space-evenly',
            backgroundColor: lighten(theme.palette.info.light, 0.8),
            borderColor: darken(theme.palette.info.light, 0.6),
            [theme.breakpoints.up('sm')]: {
              flexDirection: 'row',
            },
          })}
        >
          <LargeAmountDisplay
            currency='CLP'
            value={quotePrice}
            text='Precio actual'
          />
          <Divider
            flexItem
            orientation={isOnLargeScreen ? 'vertical' : 'horizontal'}
          />
          <LargeAmountDisplay
            currency='USD'
            value={values.inAmount ? (values.inAmount / quotePrice) : 0}
            text='Monto a recibir'
          />
        </Paper>
      </Stack>
      <ErrorDisplay
        errorMsg={status?.errorMsg}
        mt={3}
      />
      <ButtonsContainer sx={{ mt: 2 }}>
        <ButtonContainer xs={12}>
          <Button
            fullWidth
            disabled={isSubmitting}
            onClick={submitForm}
            variant='contained'
            color='warning'
          >
            COMPRAR
          </Button>
        </ButtonContainer>
      </ButtonsContainer>
    </Form>
  )
}

type BulkPurchaseCreatorProps = {
  config: BulkPurchaseConfig
}

const BulkPurchaseCreator = ({
  config,
}: BulkPurchaseCreatorProps) => {
  const formRef = React.useRef<FormikProps<FormValues>>(null)

  const { loading, data, refetch } =
    useQuery<BulkPurchaseQuoteData, BulkPurchaseQuoteVars>(
      BULK_PURCHASE_QUOTE_QUERY, {
        fetchPolicy: 'network-only',
      })

  const quote = data?.bulkPurchaseQuote

  React.useEffect(() => {
    const bulkPurchaseQuoteLoop = setInterval(() => {
      refetch()
    }, 1000)

    return () => clearInterval(bulkPurchaseQuoteLoop)
  }, [refetch])

  return (loading || !quote) ? (
    <Loading />
  ) : (
    <LocalizationProvider
      dateAdapter={AdapterDateFns}
      adapterLocale={esLocale}
    >
      <Formik
        innerRef={formRef}
        initialValues={initialValues}
        validationSchema={validationSchema(config)}
        onSubmit={() => {}}
      >
        {(props) => (
          <InnerForm
            {...props}
            config={config}
            quotePrice={quote.price}
          />
        )}
      </Formik>
    </LocalizationProvider>
  )
}

export default BulkPurchaseCreator
